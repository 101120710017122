import React, { useState, useContext, useRef } from 'react'
import { HostContext } from '../context'
import Swal from 'sweetalert2'
import NAVBAR_PUBLIC from '../layouts/navbar_public'
const Pengaduan = () => {
  document.title = "Pengaduan";
  const { HOST } = useContext(HostContext)
  const [nama_pelapor, set_nama_pelapor] = useState("")
  const [alamat, set_alamat] = useState("")
  const [aduan, set_aduan] = useState("")
  const [berkas_gambar, set_berkas_gambar] = useState("")
  const [loading, set_loading] = useState(false)
  const formPengaduan = useRef()

  const handleSimpan = async (e) => {
    e.preventDefault()
    set_loading(true)
    const data = new FormData()
    data.append('nama_pelapor', nama_pelapor)
    data.append('alamat', alamat)
    data.append('deskripsi_aduan', aduan)
    data.append('berkas_gambar', berkas_gambar)
    const requ = await fetch(`${HOST}/pengaduan/simpan`, { method: 'POST', body: data })
    try {
      const resu = await requ.json()
      if (resu.success) {
        set_nama_pelapor("")
        set_alamat("")
        set_aduan("")
        formPengaduan.current.reset()
        Swal.fire('Terima Kasih', 'Pengaduan anda telah disampaikan.', 'success');
        set_loading(false)
      } else {
        Swal.fire('Terjadi Kesalahan', 'Maaf terdapat kendala dalam penaduan, mohon untuk mencoba beberapa saat lagi . ' + resu.message, 'error')
        set_loading(false)
      }
    } catch (err) {
      console.log(`${err.message}`)
      set_loading(false)
    }
  }

  return (
    <>
      <NAVBAR_PUBLIC />
      <main>
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className='d-flex justify-content-center flex-column'>
                <img className='mt-5 align-self-center' src='/assets/img/service.png' alt={"img"} width={"25%"} />
                <p className='text-light text-center' style={{ fontFamily: 'monospace', fontSize: '16px' }}>
                  Penerangan Jalan Umum disekitarmu bermasalah ?<br />
                  Silahkan adukan melalui form berikut
                </p>
                <div className="p-2">
                  <form onSubmit={handleSimpan} ref={formPengaduan}>
                    <div className="form-group">
                      <label htmlFor="nama" className='text-light'>Nama Pelapor</label>
                      <input name={"nama"} id={"nama"} type={"text"} className={"form-control"} style={{ borderRadius: 0 }} autoComplete={"off"} onChange={e => set_nama_pelapor(e.target.value)} minLength={"3"} required />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="alamat" className='text-light'>Alamat</label>
                      <input name={"alamat"} id={"alamat"} type={"text"} className={"form-control"} style={{ borderRadius: 0 }} autoComplete={"off"} onChange={e => set_alamat(e.target.value)} minLength={3} required />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="deskripsi" className='text-light'>Deskripsi Aduan</label>
                      <textarea name={"aduan"} id={"deskripsi"} type={"text"} className={"form-control"} style={{ borderRadius: 0 }} onChange={e => set_aduan(e.target.value)} minLength={3} required></textarea>
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="file" className='text-light'>Gambar(Opsional)</label>
                      <input type={"file"} id="file" className={"form-control"} onChange={e => set_berkas_gambar(e.target.files[0])} name={"berkas_gambar"} />
                    </div>
                    <div className='d-grid gap-2'>
                      <button className='btn btn-primary mt-4' disabled={loading}>{loading ? 'Mohon tunggu...' : 'Kirim'}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )

}

export default Pengaduan