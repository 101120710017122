import React, { useContext, useEffect, useState } from 'react'
import { PuffLoader } from 'react-spinners';
import { AccessTokenContext, AuthContext, HostContext } from '../context';
import { error } from '../notify';
import DATA_SEBARAN_TERMINAL from './data_sebaran_terminal';
import { Navigate } from 'react-router-dom';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';


const Admin_pju = () => {
  window.document.title = "Sebaran Terminal";
  const { HOST } = useContext(HostContext)
  const { STATE_AUTH } = useContext(AuthContext)
  const { STATE_ACCESS_TOKEN, DISPATCH_ACCESS_TOKEN } = useContext(AccessTokenContext)
  const [loading, set_loading] = useState(false)
  const [data_perangkat, set_data_perangkat] = useState([])

  const getDevicesList = async () => {
    set_loading(true)
    const data = new FormData()
    data.append('Access-Token', STATE_ACCESS_TOKEN.access_token)
    const param = {
      method: 'POST',
      body: data
    }

    const requ = await fetch(`${HOST}/api/ambil_daftar_perangkat`, param)
    try {
      const resu = await requ.json()
      if (resu.success) {
        const _data = [
          {
            label: 'Terminal A',
            coordinate: {
              lat: 0.743563,
              lng: 124.313183
            }
          },
          {
            label: 'Terminal B',
            coordinate: {
              lat: 0.743252,
              lng: 124.3129875
            }
          },
          {
            label: 'Terminal C',
            coordinate: {
              lat: 0.7429879,
              lng: 124.3127976
            }
          },
          {
            label: 'Terminal D',
            coordinate: {
              lat: 0.7427619,
              lng: 124.3124744
            }
          },
          {
            label: 'Terminal E',
            coordinate: {
              lat: 0.7429349,
              lng: 124.3122612
            }
          }
        ]
        resu.result.map((v) => {
          switch (v.name) {
            case 'Terminal A':
              _data[0].label = v.name
              _data[0].status_perangkat = v.online;
              _data[0].status_saklar = v.status[0].value
              break;
            case 'Terminal B':
              _data[1].label = v.name
              _data[1].status_perangkat = v.online;
              _data[1].status_saklar = v.status[0].value
              break;
            case 'Terminal C':
              _data[2].label = v.name
              _data[2].status_perangkat = v.online;
              _data[2].status_saklar = v.status[0].value
              break;
            case 'Terminal D':
              _data[3].label = v.name
              _data[3].status_perangkat = v.online;
              _data[3].status_saklar = v.status[0].value
              break;
            case 'Terminal E':
              _data[4].label = v.name
              _data[4].status_perangkat = v.online;
              _data[4].status_saklar = v.status[0].value
              break;
            default:
              break;
          }
        })
        set_data_perangkat(_data)
        set_loading(false)
      } else {
        // error({ msg: resu.msg })
        set_loading(false)
        console.log(resu.msg)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const getDevicesStatus = async () => {
    const data = new FormData()
    data.append('Access-Token', STATE_ACCESS_TOKEN.access_token)
    const param = {
      method: 'POST',
      body: data
    }
    const requ = await fetch(`${HOST}/api/ambil_daftar_perangkat`, param)
    try {
      const resu = await requ.json()
      if (resu.success) {
        const _data = [
          {
            label: 'Terminal A',
            coordinate: {
              lat: 0.743563,
              lng: 124.313183
            }
          },
          {
            label: 'Terminal B',
            coordinate: {
              lat: 0.743252,
              lng: 124.3129875
            }
          },
          {
            label: 'Terminal C',
            coordinate: {
              lat: 0.7429879,
              lng: 124.3127976
            }
          },
          {
            label: 'Terminal D',
            coordinate: {
              lat: 0.7427619,
              lng: 124.3124744
            }
          },
          {
            label: 'Terminal E',
            coordinate: {
              lat: 0.7429349,
              lng: 124.3122612
            }
          }
        ]
        resu.result.map((v) => {
          switch (v.name) {
            case 'Terminal A':
              _data[0].label = v.name
              _data[0].status_perangkat = v.online;
              _data[0].status_saklar = v.status[0].value
              break;
            case 'Terminal B':
              _data[1].label = v.name
              _data[1].status_perangkat = v.online;
              _data[1].status_saklar = v.status[0].value
              break;
            case 'Terminal C':
              _data[2].label = v.name
              _data[2].status_perangkat = v.online;
              _data[2].status_saklar = v.status[0].value
              break;
            case 'Terminal D':
              _data[3].label = v.name
              _data[3].status_perangkat = v.online;
              _data[3].status_saklar = v.status[0].value
              break;
            case 'Terminal E':
              _data[4].label = v.name
              _data[4].status_perangkat = v.online;
              _data[4].status_saklar = v.status[0].value
              break;
            default:
              break;
          }
        })
        set_data_perangkat(_data)
      } else {
        // error({ msg: resu.msg })
        console.log(resu.msg)
      }
    } catch (err) {
      console.log(err.message)
    }
    return
  }

  const handleAccessTokenBaru = async () => {
    const requ = await fetch(`${HOST}/api/ambil_akses_token`)
    try {
      const resu = await requ.json()
      if (resu.success) {
        DISPATCH_ACCESS_TOKEN({ type: 'SET_ACCESS_TOKEN', ...resu.result })
        getDevicesList()
        getDevicesStatus()
      } else {
        error({ msg: 'Masalah ditemukan.' })
      }
    } catch (err) {
      console.log('Masalahn ditemukan :pada bagian getSAccessToken ', err.message)
    }
  }

  useEffect(() => {
    getDevicesList()
    const interval = setInterval(() => {
      getDevicesStatus()
    }, 10000)
    return () => { clearInterval(interval) }
  }, [])


  if (!STATE_AUTH.is_login) {
    <Navigate to={"/"} />
  }

  return (
    <main>
      <div className="container-fluid mb-3">
        <div className="row mt-3">
          <div className="col-md-6">
            <h1 className='text-light m-0 p-0'><strong>SEBARAN TERMINAL</strong></h1>
            <span className='text-light'>Pemantauan penerangan jalan umum kotamobagu</span>
          </div>
        </div>
        <div className="col-md-12">
          {
            !loading
              ?
              data_perangkat.length > 0
                ?
                <DATA_SEBARAN_TERMINAL data={data_perangkat} />
                :
                <div className='d-flex justify-content-center mt-5'>
                  <div className='d-flex flex-column'>
                    <p className='text-center p-0 m-0'><BsFillExclamationTriangleFill color='yellow' size={180} /></p>
                    <p className='text-light text-center p-0 m-0'>Perangkat tidak terhubung</p>
                    <button className='btn btn-info' onClick={handleAccessTokenBaru}>Hubungkan Kembali</button>
                  </div>
                </div>
              :
              <div className='d-flex justify-content-center align-items-center' >
                <div className='mt-5'>
                  <PuffLoader size={300} color={"aqua"} />
                </div>
              </div>
          }
        </div>
      </div>
    </main>
  )
}


export default Admin_pju