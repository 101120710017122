import React from 'react'
import { AiOutlineMenu } from "react-icons/ai";
const Navbar = () => {
  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark" style={{ backgroundColor: '#0d6efd' }}>
      <div className="container-fluid">
        <a className="navbar-brand ps-3" href="#!">
          <div className='d-flex'>
            <div className='mx-1'>
              <img src="/assets/img/logo_.png" alt="logo" width="40" />
            </div>
            <div className='d-flex flex-column'>
              <span style={{ fontSize: 14, fontWeight: 'bold' }}>SIPELITA</span>
              <span className='text-light' style={{ fontSize: '10px' }}>versi | v.1.0</span>
            </div>
          </div>
        </a>
        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><AiOutlineMenu size={24} /></button>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item text-light fw-bold">Administrator</li>
        </ul>
      </div>
    </nav>
  )
}
export default Navbar;