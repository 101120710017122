import React from "react";

import { Route, Routes } from "react-router-dom";
// import Login from "../pages/login";
import Page from "./page";

import ADMIN_DASHBOARD from "../pages/admin_dashboard";
import ADMIN_SEBARAN from "../pages/admin_sebaran";
import ADMIN_LAPORAN from "../pages/admin_laporan";
import ADMIN_PENGGUNA from "../pages/admin_pengguna";
import HOME from '../pages/home'
import PENGADUAN from '../pages/pengaduan'
import LOGIN from '../pages/login'

const Index = () => {

  const menu_admin = (
    <>
      <Route exact path="/admin" element={<Page mod={<ADMIN_DASHBOARD />} />}></Route>
      <Route exact path="/admin/sebaran" element={<Page mod={<ADMIN_SEBARAN />} />}></Route>
      <Route exact path="/admin/pengaduan" element={<Page mod={<ADMIN_LAPORAN />} />}></Route>
      <Route exact path="/admin/pengguna" element={<Page mod={<ADMIN_PENGGUNA />} />}></Route>
    </>
  )

  return (
    <>
      <Routes>
        <Route exact path="/" element={<HOME />}></Route>
        <Route path="/pengaduan" element={<PENGADUAN />}></Route>
        <Route path="/login" element={<LOGIN />}></Route>
        {menu_admin}
        <Route exact path="/*" element={<LOGIN />}></Route>
      </Routes>
    </>
  );
};

export default Index;
