import React, { useContext } from "react";
import { FaMapMarkedAlt, FaRegCommentAlt, FaSignOutAlt, FaTachometerAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom"
import { AccessTokenContext, AuthContext } from "../context";
const Sidebar = () => {

  const { DISPATCH_AUTH } = useContext(AuthContext)
  const { DISPATCH_ACCESS_TOKEN } = useContext(AccessTokenContext)
  const handleLogout = () => {
    DISPATCH_ACCESS_TOKEN({ type: 'RESET_ACCESS_TOKEN' })
    DISPATCH_AUTH({ type: 'LOGOUT' })
    setTimeout(() => {
      window.location.reload(true)
    }, 1);
  }

  const menu_admin = (
    <div className="nav">
      <h6 className="sb-sidenav-menu-heading border-bottom border-1 bg-dark">Menu</h6>
      <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-primary active' : 'nav-link'} to="/admin" end><div className="sb-nav-link-icon"><FaTachometerAlt size={20}/></div>DASHBOARD</NavLink>
      <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-primary active' : 'nav-link'} to="/admin/sebaran"><div className="sb-nav-link-icon"><FaMapMarkedAlt size={20} /></div> SEBARAN</NavLink>
      <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-primary active' : 'nav-link'} to="/admin/pengaduan"><div className="sb-nav-link-icon"><FaRegCommentAlt size={20} /></div> PENGADUAN</NavLink>
      <NavLink  onClick={handleLogout} className="nav-link" to="/login"><div className="sb-nav-link-icon"><FaSignOutAlt size={20} /></div> KELUAR</NavLink>
    </div>
  )

  return (
    <>
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion" style={{ backgroundColor: '#3b414d' }}>
        <div className="sb-sidenav-menu">
          {menu_admin}
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Login Sebagai:</div>
          Administrator
        </div>
      </nav>
    </>

  )
}

export default Sidebar