import React, { useState, useContext } from 'react';
import { AccessTokenContext, AuthContext, HostContext } from '../context';
import {  useNavigate } from 'react-router-dom';
import { error, content as ContentNotif } from '../notify';
import { PuffLoader, ClockLoader } from 'react-spinners';
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import NAVBAR_PUBLIC from '../layouts/navbar_public';

const Login = () => {
  window.document.title = "Masuk Pengguna"

  const { HOST } = useContext(HostContext)
  const { DISPATCH_AUTH } = useContext(AuthContext)
  const { STATE_ACCESS_TOKEN, DISPATCH_ACCESS_TOKEN } = useContext(AccessTokenContext)

  const direct = useNavigate()
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isVerificationSuccess, setVerificationSuccess] = useState(false)
  const [isGenerateAccessTokenSuccess, setGenerateAccessTokenSuccess] = useState(false)
  const [isVerificationEvent, setVerificationEvent] = useState(false)
  const [isGenerateAccessTokenEvent, setGenerateAccessTokenEvent] = useState(false)
  const [isLoadingAccessToken, setLoadingAccessToken] = useState(false)
  const [isTimeDirect, setTimeDirect] = useState(false)
  const [time, setTime] = useState(3)

  const countDownDirect = () => {
    let i = 3;
    setTimeout(() => {
      setTimeDirect(true)
    }, 700)
    const interval = setInterval(() => {
      if (i === 0) {
        clearInterval(interval)
        direct('/admin')
      } else {
        setTime(i)
        i--;
      }
    }, 1000)
  }

  const getAccessToken = async () => {
    setGenerateAccessTokenEvent(true)
    setLoadingAccessToken(true)
    const requ = await fetch(`${HOST}/api/ambil_akses_token`)
    try {
      const resu = await requ.json()
      if (resu.success) {
        setLoadingAccessToken(false)
        setGenerateAccessTokenSuccess(true)
        DISPATCH_ACCESS_TOKEN({ type: 'SET_ACCESS_TOKEN', ...resu.result })
        countDownDirect()
      } else {
        error({ msg: 'Masalah ditemukan.' })
        setLoadingAccessToken(false)
      }
    } catch (err) {
      console.log('Masalahn ditemukan :pada bagian getSAccessToken ', err.message)
      setLoadingAccessToken(false)
    }
  }

  const handleSubmit = async (e) => {
    setVerificationEvent(true)
    e.preventDefault()
    setLoading(true)
    setError(false)

    const param = new FormData()
    param.append("nama_pengguna", user)
    param.append("kata_sandi", pass)

    const requ = await fetch(`${HOST}/auth/login`, { method: 'POST', body: param })
    try {
      const resu = await requ.json()
      if (resu.success) {
        DISPATCH_AUTH({ type: 'LOGIN', ...resu.data })
        setVerificationSuccess(true)
        if (STATE_ACCESS_TOKEN.access_token) {
          setGenerateAccessTokenEvent(true)
          setLoadingAccessToken(false)
          setGenerateAccessTokenSuccess(true)
          countDownDirect()
        } else {
          getAccessToken()
        }
        setLoading(false)
      } else {
        error({ msg: resu.message })
        setLoading(false)
      }
      setLoading(false)
    } catch (err) {
      error({ msg: err.message })
      setError(true)
      setLoading(false)
    }
  }

  return (
    <>
      <NAVBAR_PUBLIC />
      <main>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="text-center mt-5"><img className="img-fluid" src={'/assets/img/logo.png'} width="50%" alt="logo"></img></div>
              <div className="card shadow-lg border-0 rounded-lg" style={{ borderRadius: 0 ,background:'#f7f7f7'}}>
                <div className="card-header">
                  <div className='text-center mt-4'>
                    <p className="text-center font-weight-light lh-1">
                      <span className='h3'>MASUK PENGGUNA</span> <br />
                      {/* <span style={{ fontSize: '12px' }}>versi v1.0</span> */}
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <input style={{ border: '1px solid #1B1464', borderRadius: 0 }} className="form-control" name="username" type="text" autoComplete="off" id="inputUsername" value={user} onChange={e => setUser(e.target.value)} required />
                      <label htmlFor="inputUsername">Nama Pengguna</label>
                    </div>
                    <div className="form-floating">
                      <input style={{ border: '1px solid #1B1464', borderRadius: 0 }} className="form-control mb-3" name="password" type="password" autoComplete="off" id="inputPassword" value={pass} onChange={e => setPass(e.target.value)} required />
                      <label htmlFor="inputPassword">Kata Sandi</label>
                    </div>
                    <div className="d-grid gap-2">
                      {
                        isLoading
                          ?
                          (<button className="btn btn-warning" type="button" disabled>Masuk</button>
                          )
                          :
                          <button style={{ borderRadius: 0 }} className="btn btn-warning" type="submit">Masuk</button>
                      }
                      {
                        isError && (<div className="alert alert-danger text-center" type="button">Tidak bisa login! Terjadi kesalahan sistem.</div>)
                      }

                    </div>
                  </form>
                </div>
                <div className="card-footer text-center py-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-center">
                        <p style={{ fontSize: '10px' }}><strong>SIPELITA</strong> copyright&copy; 2022 - supported by Diskominfo kotamobagu</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column bg-white p-2'>
                {
                  isVerificationEvent && (
                    isLoading
                      ?
                      <div className='d-flex'>
                        <PuffLoader size={20} /> <small>Memverifikasi akun...</small>
                      </div>
                      :
                      isVerificationSuccess
                        ?
                        <div className='d-flex'>
                          <BsCheck2Circle size={20} color={'green'} /> <small className='text-success'>Verifikasi akun berhasil</small>
                        </div>
                        :
                        <div className='d-flex'>
                          <BsXCircle size={20} color={'red'} /> <small className='text-danger'>Verifikasi akun gagal</small>
                        </div>
                  )
                }

                {
                  isGenerateAccessTokenEvent && (
                    isLoadingAccessToken
                      ?
                      <div className='d-flex'>
                        <PuffLoader size={20} /> <small>Mengenerate akses kode IoT...</small>
                      </div>
                      :
                      isGenerateAccessTokenSuccess
                        ?
                        <>
                          <div className='d-flex'>
                            <BsCheck2Circle size={20} color={'green'} /> <small className='text-success'>Generate akses kode IoT berhasil</small>
                          </div>
                          {
                            isTimeDirect && (
                              <div className='d-flex'>
                                <ClockLoader size={20} color={'green'} /> <small className='text-success'>Halaman akan dialihkan dalam {time} detik</small>
                              </div>
                            )
                          }
                        </>
                        :
                        <div className='d-flex'>
                          <BsXCircle size={20} color={'red'} /> <small className='text-danger'>Generate akses kode IoT gagal</small>
                        </div>
                  )

                }

              </div>
            </div>
          </div>
        </div>
        <ContentNotif />
      </main>
    </>
  );
}

export default Login;