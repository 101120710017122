import React from 'react'
import { GoogleMap, MarkerF, InfoWindow } from '@react-google-maps/api';
import { BsInfoCircle } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { HostContext } from '../context';
import { error } from '../notify';
const Data_sebaran_terminal = ({ data }) => {

  const [menu_infowindow, set_menu_infowindow] = useState(false)
  const [menu_mode, set_menu_mode] = useState(!false)
  const [data_prepare, set_data_prepare] = useState(data)

  const { HOST } = useContext(HostContext)

  const containerStyle = {
    width: '100%',
    height: '500px'
  };

  const center = {
    lat: 0.743075,
    lng: 124.312873
  };

  const mode_night = [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ]

  const mode_day = [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]

  const divStyle = {
    background: `#1B1464`,
    border: `1px solid #1B1464`,
    padding: 1,
    color: '#fff'
  }

  const setInfoWindow = (val) => {
    return (
      <InfoWindow
        position={val.coordinate}
      >
        <div style={divStyle}>
          <strong>{val.label}</strong>
        </div>
      </InfoWindow>
    )
  }

  const setMarker = (data) => {

    return data.map((v, i) => {
      if (v.status_perangkat === false && v.status_saklar === false) {
        return <MarkerF
          key={i}
          title={v.label}
          icon={"/assets/img/notif/png/notif_merah.png"}
          position={v.coordinate}>
          {menu_infowindow ? setInfoWindow(v) : null}
        </MarkerF>
      }
      if (v.status_perangkat === true && v.status_saklar === true) {
        return <MarkerF
          key={i}
          title={v.label}
          icon={"/assets/img/notif/png/notif_hijau.png"}
          position={v.coordinate} >
          {menu_infowindow ? setInfoWindow(v) : null}
        </MarkerF>
      }
      if (v.status_perangkat === false && v.status_saklar === true) {
        return <MarkerF
          key={i}
          title={v.label}
          icon={"/assets/img/notif/png/notif_kuning.png"}
          position={v.coordinate}>
          {menu_infowindow ? setInfoWindow(v) : null}
        </MarkerF>
      }
      if (v.status_perangkat === true && v.status_saklar === false) {
        return <MarkerF
          key={i}
          title={v.label}
          icon={"/assets/img/notif/png/notif_abu.png"}
          position={v.coordinate}>
          {menu_infowindow ? setInfoWindow(v) : null}

        </MarkerF>
      }
      return true
    })

  }

  useEffect(() => {
    // cek perbedaan status
    const cekStatusPerangkatSaklarSaatIni = (e) => {
      const ls = localStorage;
      e.map((v, i) => {
        if (!ls.getItem(`P${v.label}`) && !ls.getItem(`S${v.label}`)) {
          ls.setItem(`P${v.label}`, JSON.stringify({ status_perangkat: v.status_perangkat }))
          ls.setItem(`S${v.label}`, JSON.stringify({ status_saklar: v.status_saklar }))
        } else {

          // cek status perangkat
          if (!JSON.parse(ls.getItem(`P${v.label}`)).status_perangkat === v.status_perangkat) {
            console.log('Perangkat Sudah beda -', `P${v.label}`)

            const getStatusNotif = async () => {
              const data = new FormData()
              data.append('PERANGKAT[type]', 'PERANGKAT')
              data.append('PERANGKAT[label]', v.label)
              data.append('PERANGKAT[status]', v.status_perangkat)
              try {
                await fetch(`${HOST}/pengaduan/notif`, { method: 'POST', body: data })
              } catch (err) {
                error({ msg: err })
              }
            }
            getStatusNotif()
            ls.setItem(`P${v.label}`, JSON.stringify({ status_perangkat: v.status_perangkat }))
          }

          // cek status saklar
          if (!JSON.parse(ls.getItem(`S${v.label}`)).status_saklar === v.status_saklar) {
            const getStatusNotif = async () => {
              const data = new FormData()
              data.append('SAKLAR[type]', 'SAKLAR')
              data.append('SAKLAR[label]', v.label)
              data.append('SAKLAR[status]', v.status_saklar)
              try {
                await fetch(`${HOST}/pengaduan/notif`, { method: 'POST', body: data })
              } catch (err) {
                error({ msg: err })
              }
            }
            getStatusNotif()
            ls.setItem(`S${v.label}`, JSON.stringify({ status_saklar: v.status_saklar }))
          }
        }
        return true
      })
    }
    cekStatusPerangkatSaklarSaatIni(data)
    setTimeout(() => {
      set_data_prepare(false);
      set_data_prepare(data);
    }, 1)
    return () => { set_data_prepare(false) }
  }, [data, HOST])

  return (
    <>
      <div className='border border-primary'>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={18.8}
          options={{
            disableDefaultUI: false,
            fullscreenControl: true,
            zoomControl: false,
            styles: menu_mode ? mode_night : mode_day
          }}
        >
          {
            data_prepare ? setMarker(data) : null
          }
        </GoogleMap>
        <div className='d-flex' style={
          {
            marginTop: '-35px',
            position: 'relative',
            zIndex: '999999 !important'
          }
        }>
          <button className='btn btn-primary btn-sm mx-2' onClick={() => set_menu_infowindow(!menu_infowindow)} style={{ borderRadius: 0 }}>Label Perangkat</button>
          <button className='btn btn-primary btn-sm' onClick={() => set_menu_mode(!menu_mode)} style={{ borderRadius: 0 }}>{menu_mode ? 'Mode Siang' : 'Mode Malam'}</button>
        </div>
      </div>
      <div className="border-bottom border-start border-end border-primary p-2">
        <h6 className='text-light'><BsInfoCircle /> Keterangan Status Indikator</h6>
        <ul className='list-group border-none'>
          <li className='list-group-item border-none' style={{ fontSize: '14px', fontFamily: 'monospace', backgroundColor: '#eaeaeb' }}><img src='/assets/img/notif/png/notif_merah.png' alt='indikator_notif' /> <strong>PERANGKAT</strong> dalam keadaan offline, <strong>SAKLAR</strong> dalam keadaan padam</li>
          <li className='list-group-item border-none' style={{ fontSize: '14px', fontFamily: 'monospace' }}><img src='/assets/img/notif/png/notif_hijau.png' alt='indikator_notif' /> <strong>PERANGKAT</strong> dalam keadaan online, <strong>SAKLAR</strong> dalam keadaan nyala</li>
          <li className='list-group-item border-none' style={{ fontSize: '14px', fontFamily: 'monospace', backgroundColor: '#eaeaeb' }}><img src='/assets/img/notif/png/notif_kuning.png' alt='indikator_notif' /> <strong>PERANGKAT</strong> dalam keadaan offline, <strong>SAKLAR</strong> dalam keadaan nyala</li>
          <li className='list-group-item border-none' style={{ fontSize: '14px', fontFamily: 'monospace' }}><img src='/assets/img/notif/png/notif_abu.png' alt='indikator_notif' /> <strong>PERANGKAT</strong> dalam keadaan online, <strong>SAKLAR</strong> dalam keadaan padam</li>
        </ul>
        <h6 className='text-light mt-2'><BsInfoCircle /> Detail Perangkat</h6>
        <ul className='list-group'>
          <li className='list-group-item' style={{ fontSize: '14px', fontFamily: 'monospace', backgroundColor: '#eaeaeb' }}>Nama Produk : <strong>Bardi Smart Breaker</strong></li>
          <li className='list-group-item' style={{ fontSize: '14px', fontFamily: 'monospace' }}>Model : <strong>WBR2 8720</strong></li>
        </ul>
      </div>
    </>
  )
}

export default Data_sebaran_terminal