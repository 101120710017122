import React from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { MdLogin, MdOutlineInsertComment } from "react-icons/md";
import NAVBAR_PUBLIC from '../layouts/navbar_public';
const Home = () => {
  document.title = "Beranda"
  return (
    <>
      <NAVBAR_PUBLIC />
      <main className='mb-3'>
        <div class="container">
          <div className="row mt-3">
            <div className="col-md-12">
              <img className='' src='/assets/img/banner.png' width={'100%'} alt="Banner" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="border border-primary p-2">
                <p className='text-light p-o m-0'>
                  <BsInfoCircle size={16} className='text-light' /> TENTANG SISTEM
                </p>
                <p className='text-light'>
                  <strong>SiPELITA</strong> Adalah sistem untuk memonitoring, pemantauan dan pengendalian alat penerangan jalan di kotamobagu.
                  <br />Pilih menu dibawah untuk aktifitas anda.
                </p>
              </div>
              <div class="row mt-3">
                <div class="col-md-6 mt-2">
                  <Link to={'/pengaduan'} className='btn btn-primary btn-lg w-100' style={{ borderRadius: 0 }}>
                    <p className='p-1'>
                      <MdOutlineInsertComment size={68} /><br />
                      Pengaduan
                    </p>
                  </Link>
                </div>
                <div class="col-md-6 mt-2">
                  <Link to={'/login'} className='btn btn-primary btn-lg w-100' style={{ borderRadius: 0 }}>
                    <p className='p-1'>
                      <MdLogin size={68} /> <br />
                      Masuk Pengguna
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Home