import React, { useReducer } from 'react';
import ReactDOM from 'react-dom/client';
import Route from './routes'
import { BrowserRouter as Router } from 'react-router-dom';
import { AccessTokenContext, AuthContext, HostContext } from './context';
import { AccessTokenReducers, AccessTokenInit, AuthInit, AuthReducers } from './reducers';
import { content as ContentNotif } from './notify';
const App = () => {
  const HOST = process.env.REACT_APP_HOST_WEBSERVICES
  const [STATE_AUTH, DISPATCH_AUTH] = useReducer(AuthReducers, AuthInit)
  const [STATE_ACCESS_TOKEN, DISPATCH_ACCESS_TOKEN] = useReducer(AccessTokenReducers, AccessTokenInit)
  return (
    <HostContext.Provider value={{ HOST }}>
      <AuthContext.Provider value={{ STATE_AUTH, DISPATCH_AUTH }}>
        <AccessTokenContext.Provider value={{ STATE_ACCESS_TOKEN, DISPATCH_ACCESS_TOKEN }}>
          <Route />
        </AccessTokenContext.Provider>
      </AuthContext.Provider>
    </HostContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router basename='/'>
      <App />
      <ContentNotif />
    </Router>
  </React.StrictMode>
);