import React, { useContext } from 'react'
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { Navigate, useNavigate } from 'react-router-dom';
import { AccessTokenContext, AuthContext } from '../context';
import { FaMapMarkedAlt, FaRegCommentAlt, FaSignOutAlt } from 'react-icons/fa'

const Admin_dashboard = () => {
  document.title = "Beranda"
  const { STATE_AUTH, DISPATCH_AUTH } = useContext(AuthContext)
  const { DISPATCH_ACCESS_TOKEN } = useContext(AccessTokenContext)
  const direct = useNavigate()
  const handleLogout = () => {
    DISPATCH_ACCESS_TOKEN({ type: 'RESET_ACCESS_TOKEN' })
    DISPATCH_AUTH({ type: 'LOGOUT' })
    setTimeout(() => {
      window.location.reload(true)
    }, 1);
  }
  if (!STATE_AUTH.is_login) {
    return <Navigate to={"/"} />
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            {/* <img src='/assets/img/banner.jpg' width={'100%'} alt="Banner" /> */}
            <div className="alert  alert-primary" style={{ borderRadius: '0' }}>
              <div style={{ lineHeight: 0.8 }}>
                <h3>Selamat datang di <strong>SiPELITA</strong></h3>
                <p style={{ fontFamily: 'monospace' }}>Sistem ini digunakan untuk memonitoring, pemantauan dan pengendalian alat penerangan jalan di kotamobagu</p>
              </div>
              <div className="col-md-12 mt-1">
                <div className='border-top border-primary p-1 d-flex m-0' style={{ borderRadius: 0 }}>
                  <BsFillPersonCheckFill size={42} />
                  <div className="mx-3">
                    <p className='p-0 m-0' style={{ fontSize: '14px' }}> Pengguna sistem : <strong>Administrator</strong> </p>
                    <p style={{ fontSize: '14px' }} className='p-0 m-0'>Status akses : <span className='p-0 m-0 fw-bold' style={{ fontSize: '14px' }}>SUPER ADMIN</span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div className="row">
                  <div className="col-md-4 mt-3 mb-1" onClick={() => { direct('/admin/sebaran') }} style={{ cursor: 'pointer' }}>
                    <div className="alert bg-primary d-flex justify-content-between align-items-center text-light" style={{ borderRadius: 0 }}>
                      <div>
                        <h2 className='p-0 m-0 fw-bold'>SEBARAN</h2>
                        <small>Pantau terminal terpasang tiap sudut penerangan jalan umum</small>
                      </div>
                      <FaMapMarkedAlt size={48} />
                    </div>
                  </div>

                  <div className="col-md-4 mt-3 mb-1" onClick={() => { direct('/admin/pengaduan') }} style={{ cursor: 'pointer' }}>
                    <div className="alert bg-primary d-flex justify-content-between align-items-center text-light" style={{ borderRadius: 0 }}>
                      <div>
                        <h2 className='p-0 m-0 fw-bold'>PENGADUAN</h2>
                        <small>Pantau pengaduan masyarakat terkait penerangan jalan umum</small>
                      </div>
                      <FaRegCommentAlt size={48} />
                    </div>
                  </div>

                  <div className="col-md-4 mt-3 mb-1" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                    <div className="alert bg-danger d-flex justify-content-between align-items-center text-light" style={{ borderRadius: 0 }}>
                      <div>
                        <h2 className='p-0 m-0 fw-bold'>KELUAR SISTEM</h2>
                        <small>Keluar dari sistem</small>
                      </div>
                      <FaSignOutAlt size={48} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
  )
}

export default Admin_dashboard
