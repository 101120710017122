const ls = localStorage;
const ul = "_USER_LOGIN_";
const ud = "_USER_DATA_";

const ls_login = ls.getItem(ul)
const ls_usdat = ls.getItem(ud)

let prepare_data = {}
if (ls_login) {
  prepare_data = JSON.parse(ls_usdat)
} else {
  prepare_data = {
    is_login: false,
    is_admin: false,
    nama: null,
    nama_pengguna: null,
    akses: null,
    token: null
  }
}

export const AuthInit = prepare_data

export const AuthReducers = (state, receive) => {
  const rec = receive;
  switch (rec.type) {
    case 'LOGIN':
      const set_data = {
        ...state,
        is_login: true,
        is_admin: true,
        nama: rec.nama_lengkap,
        nama_pengguna: rec.nama_pengguna,
        akses: rec.akses,
        token: rec.token
      }
      ls.setItem(ul, set_data.is_login)
      ls.setItem(ud, JSON.stringify(set_data))

      return set_data

    case 'GANTI_TOKEN':
      const update_data = { ...state, token: rec.token }
      ls.setItem(ud, JSON.stringify(update_data))
      return update_data
    case 'LOGOUT':
      ls.removeItem(ul)
      ls.removeItem(ud)
      ls.removeItem('_ACCESS_TOKEN_')
      return {}
    default:
      return state
  }
}
