import React from 'react'

const Admin_masyarakat = () => {
  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 className='text-light'><strong><i className='far fa-building'></i> PENGGUNA SISTEM</strong></h1>
          </div>
        </div>
        <hr style={{heigth:'0.5px'}} className='bg-primary'/>
        <div className="row mt-3">
          <h1 className='text-light'>Hello World</h1>
        </div>
      </div>
    </main>
  )
}

export default Admin_masyarakat