const ls = localStorage;
const ul = "_USER_LOGIN_";
const at = "_ACCESS_TOKEN_";

const ls_login = ls.getItem(ul)
const ls_acctk = ls.getItem(at)

let prepare_access_token_data = {}
if (ls_login) {
  if (ls_acctk) {
    prepare_access_token_data = JSON.parse(ls_acctk)
  } else {
    prepare_access_token_data = {
      access_token: null,
      uid: null,
      refresh_token: null,
      expire_time: null
    }
  }
} else {
  prepare_access_token_data = {
    access_token: null,
    uid: null,
    refresh_token: null,
    expire_time: null
  }
}
export const AccessTokenInit = prepare_access_token_data

export const AccessTokenReducers = (state, receive) => {
  const rec = receive;
  switch (rec.type) {
    case 'SET_ACCESS_TOKEN':
      const set_data = {
        access_token: rec.access_token,
        refresh_token: rec.refresh_token,
        uid: rec.uid,
        expire_time: rec.expire_time
      }
      ls.setItem(at, JSON.stringify(set_data))
      return set_data;
    case 'GET_ACCESS_TOKEN':
      const data = JSON.parse(ls.getItem(at))
      return data;
    case 'REFRESH_TOKEN':
      const new_data = {
        access_token: rec.access_token,
        refresh_token: rec.refresh_token,
        uid: rec.uid,
        expire_time: rec.expire_time
      }
      ls.setItem(at, JSON.stringify(new_data))
      return new_data
    case 'RESET_ACCESS_TOKEN':
      return {};
    default:
      return state
  }
}