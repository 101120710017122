import { Sidebar, Navbar } from '../layouts';
import React from 'react';
import { LoadScript } from '@react-google-maps/api';
import { NavLink } from 'react-router-dom';
const Page = (props) => {
      const { mod } = props;
      return (
            <div>
                  <div className="sb-nav-fixed">
                        <Navbar />
                        <div id="layoutSidenav">
                              <div id="layoutSidenav_nav">
                                    <Sidebar />
                              </div>
                              <div id="layoutSidenav_content" style={{ backgroundColor: '#0e0e3c' }}>
                                    <main>
                                          <div className="container-fluid ">
                                                <LoadScript
                                                      googleMapsApiKey="AIzaSyBepkagX4-wrHXOXA-sFp0kCH5NX7FENkI"
                                                      onUnmount={() => { return null }}
                                                >
                                                      {mod}
                                                </LoadScript>
                                          </div>
                                    </main>
                                    <footer className="py-4 bg-light mt-auto">
                                          <div className="container-fluid">
                                                <div className="d-flex align-items-center justify-content-between small">
                                                      <div className="text-muted">Copyright &copy; SiPELITA Kotamobagu 2022 | Pemerintah Kota Kotamobagu</div>
                                                      <div>
                                                            <NavLink to="#privacy">Privacy Policy</NavLink>
                                                            &middot;
                                                            <NavLink to="#kondision">Terms &amp; Conditions</NavLink>
                                                      </div>
                                                </div>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Page;
