import React from 'react'
import { useContext, useEffect, useState,useCallback } from 'react'
import { BsCheck, BsCheck2Circle, BsFileArrowDown, BsFileX, BsInboxFill } from 'react-icons/bs'
import { PuffLoader } from 'react-spinners'
import { AuthContext, HostContext } from '../context'
import { error, success } from '../notify'
import { Navigate } from 'react-router-dom';
import { setTanggal } from '../utils'

const Admin_laporan = () => {
  document.title = "Pengaduan Masuk"
  const { HOST } = useContext(HostContext)
  const { STATE_AUTH } = useContext(AuthContext)
  const [data_aduan, set_data_aduan] = useState([])
  const [loading, set_loading] = useState(false)
  const [loading_tanggap, set_loading_tanggap] = useState(false)

  const getDataAduan = useCallback(async () => {
    set_loading(true)
    const requ = await fetch(`${HOST}/pengaduan/ambil`, { method: 'GET' })
    try {
      const resu = await requ.json()
      set_data_aduan(resu.data)
      set_loading(false)
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }, [HOST])

  useEffect(() => {
    getDataAduan()
  }, [HOST, getDataAduan])

  const handleTanggapi = async (id) => {
    set_loading_tanggap(true)
    try {
      const requ = await fetch(`${HOST}/pengaduan/terima/${id}`, { method: 'GET' })
      const resu = await requ.json()
      if (resu.success) {
        console.log(resu.message)
        success({ msg: resu.message })
        getDataAduan()
        set_loading_tanggap(false)
      }
    } catch (err) {
      error({ msg: 'Terdapat kesahalan : ' + err.message })
      set_loading_tanggap(false)
    }
  }

  if (!STATE_AUTH.is_login) {
    return <Navigate to={"/"} />
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 className='text-light'><strong><i className='far fa-building'></i> PENGADUAN MASUK</strong></h1>
            <span className='text-light'>Pantau aduan masyarakat terkait penerangan jalan umum </span>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-4">
            <div class="alert alert-light d-flex justify-content-between flex-column border-primary" style={{ borderRadius: '0' }}>
              <h6>Total aduan masuk</h6>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='fw-bold'>{data_aduan.length}</h2>
                <BsFileArrowDown size={48} className={'text-primary'} />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="alert alert-light d-flex justify-content-between flex-column border-success" style={{ borderRadius: '0' }}>
              <h6>Total aduan ditanggapi</h6>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='fw-bold'>{data_aduan.filter(v => { return v.status_baca === '1' }).length}</h2>
                <BsCheck2Circle size={48} className={'text-success'} />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="alert alert-light d-flex justify-content-between flex-column border-danger" style={{ borderRadius: '0' }}>
              <h6>Total aduan belum ditanggapi</h6>
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='fw-bold'>{data_aduan.filter(v => { return v.status_baca === '0' }).length}</h2>
                <BsInboxFill size={48} className={'text-danger'} />
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12">

            {
              !loading
                ?
                data_aduan.length > 0
                  ?
                  <>
                    <div className="accordion" id="accordionExample" style={
                      {
                        height: `calc(${window.innerHeight}px - 375px)`,
                        width: '100%',
                        overflowY: 'auto',
                        background: 'rgba(247, 247, 247,0.1)',
                        padding: '0 10px'
                      }}>
                      {
                        data_aduan.map((v, i) => {
                          return (
                            <div className="accordion-item my-3" key={i}>
                              <h2 className="accordion-header" id={`heading-${i}`}>
                                <button className={v.status_baca === '0' ? `accordion-button collapsed position-relative` : 'accordion-button collapsed  position-relative'} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded="false" aria-controls={`collapse-${i}`}>
                                  <span className=''>Pengaduan masuk : <strong>{setTanggal(v.tanggal_dibuat)}</strong></span>
                                  {v.status_baca === '0' ? <span className={'position-absolute top-0 start-0 translate-middle p-2 bg-danger border border-light rounded-circle'}></span> : ''}
                                </button>
                              </h2>
                              <div id={`collapse-${i}`} className="accordion-collapse collapse" aria-labelledby={`heading-${i}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <h5 className='m-0 p-0 '><strong>Pelapor</strong></h5>
                                  <p style={{ fontFamily: 'monospace' }}>{v.nama_pelapor}</p>
                                  <h5 className='m-0 p-0 '><strong>Alamat</strong></h5>
                                  <p style={{ fontFamily: 'monospace' }}>{v.alamat}</p>
                                  <h5 className='m-0 p-0 mt-2'><strong>Deskripsi Laporan</strong></h5>
                                  <p style={{ fontFamily: 'monospace' }}>{v.deskripsi_pengaduan}</p>
                                  <h5 className='mt-2'><strong>Gambar</strong></h5>
                                  <div className='row'>
                                    <div className="col-md-12">
                                      <img src={v.berkas_gambar} alt={"gambar"} className="w-50" />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      {
                                        v.status_baca === '1'
                                          ?
                                          <button className='btn btn-success' style={{ borderRadius: '0' }} disabled> Ditanggapi <BsCheck size={24} /></button>
                                          :
                                          <button onClick={() => handleTanggapi(v.id_pengaduan)} className='btn btn-primary' style={{ borderRadius: '0' }} disabled={loading_tanggap}> {loading_tanggap ? "Menanggapi..." : "Tanggapi"}</button>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                  :
                  <div className='d-flex justify-content-center mt-5'>
                    <div className='d-flex flex-column'>
                      <p className='text-center p-0 m-0'><BsFileX color='yellow' size={180} /></p>
                      <p className='text-light text-center p-0 m-0'>Tidak ada laporan masuk.!</p>
                    </div>
                  </div>
                :
                <div className='d-flex justify-content-center align-items-center' >
                  <div className='mt-5'>
                    <PuffLoader size={300} color={"aqua"} />
                  </div>
                </div>

            }
          </div>
        </div>
      </div>
    </main>
  )
}


export default Admin_laporan